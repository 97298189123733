@import "../../mixins.scss";

.testimonial-section {
  @include flex();
  flex-direction: row-reverse;
  padding: 0 5%;
  margin: 60px 0;
  .left {
    justify-self: flex-start;
    flex: 1.1;
    .label{
      @include label();
      color: var(--primary-color);
      // margin-left: 45px;
    }
    h3 {  
      @include heading2();
      margin:5px 0;
      color: var(--primary-text);
      // margin-left: 45px;
    }
    .desc{   
      @include para();
      color: var(--primary-text);
      // margin-left: 45px;
      margin-bottom: 20px;
    }
    .testimonial-slider {
      max-width: 600px;
      overflow-x: hidden;
      margin: 0 0;

      .swiper-pagination-bullets-dynamic {
        left: 70px !important;
        width: unset;
        bottom: 10px;
        .swiper-pagination-bullet {
          background-color: #d9d9d9;
          opacity: 1;
          height: 8px;
          width: 30px;
          transition: var(--advance);
          margin: 0 4px;
          border-radius: 10px !important;

          &:hover {
            transform: scale(1.1);
          }
        }
        .swiper-pagination-bullet-active {
          background-color: var(--primary-color) !important;
        }
      }
    }
  }

  .right {
    flex: 0.9;
    position: relative;
    img[alt="spikes-icon"] {
      position: absolute;
      top: -35px;
      left: -110px;
      height: 90px;
      width: auto;
    }
  }

  @media (max-width: 913px) {
    flex-direction: column-reverse;

    .right{
      margin-bottom: 70px;
      text-align: center;
      img{
        width: 70%;
      }
    }

    .left {
      .label, h3, .desc{
        text-align: center;
      }
    }
  }

  @media (max-width: 800px) {
    .left{
      .testimonial-slider{
        width: 350px;
        margin: 0 auto !important;
        text-align: center;
  
        .swiper-pagination-bullets-dynamic{
          left: 50% !important;
        }
      }
    }
  }

}
@media screen and (max-width: 767px){
  .testimonial-section .left .testimonial-slider{
    padding-bottom: 30px;
  }
}