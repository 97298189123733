@import "../../mixins.scss";

.testimonial-card2 {
  .main {
    @include flex();
    .image {
      flex: 0.6;
      img{
        width: 90%;
      }
    }
    .content {
      flex: 1.4;
      .name {
        @include heading2();
        color: var(--primary-text);
      }
      .review {
        @include para();
        font-size: 1.4rem;
        color: var(--primary-text);
      }
    }
  }

  .rating {
    width: 120px;
    height: auto;
    margin-top: 10px;
    margin-left: 25px;
  }

  @media (max-width: 800px) {
    .main{
        flex-direction: column;
        text-align: center;
        .image{
            img{
                width: 20%;
            }
        }
    }

    .rating{
        margin: 10px auto 0 auto;
    }
        
  }
}
